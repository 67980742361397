import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import logo from "../images/templogo.png";
import styles from "../styles/App.module.css";
import HoverVideoPlayer from "react-hover-video-player";
import videoData from "../data/video-data.json";

function App() {
  const [displayNavMenu, setDisplayNavMenu] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const [desc, setDesc] = useState("");
  const video = useRef();
  let [year, setYear] = useState("2020");
  const maxVideoAmount = 9; // setter hvor mange videoer som viser pr år

  function goto(id) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }

  function playVideo(video) {
    setActiveVideo(video);
  }

  function stopVideo() {
    setActiveVideo(null);
  }

  useEffect(() => {}, [activeVideo, year]);

  const rederSwitch = (param) => {
    switch (param) {
      case "2020":
        let videos2020 = [];
        for (let i = 1; i <= maxVideoAmount; i++) {
          if (videoData["2020"][i - 1].description !== "") {
            if (videoData["2020"][i - 1].thumbnail) {
              videos2020.push(
                <HoverVideoPlayer
                  key={i}
                  pausedOverlay={
                    <img
                      src={`Videos/2020/thumbnails/${i}.png`}
                      alt=""
                      style={{
                        // Make the image expand to cover the video's dimensions
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  videoSrc={`/Videos/2020/${i}.mp4`}
                  restartOnPaused
                  preload="metadata"
                  onClick={() => {
                    playVideo(`/Videos/2020/${i}.mp4`);
                    setDesc(videoData["2020"][i - 1].description);
                  }}
                />
              );
              continue;
            }
            videos2020.push(
              <HoverVideoPlayer
                key={i}
                videoSrc={`/Videos/2020/${i}.mp4`}
                restartOnPaused
                preload="metadata"
                onClick={() => {
                  playVideo(`/Videos/2020/${i}.mp4`);
                  setDesc(videoData["2020"][i - 1].description);
                }}
              />
            );
          }
        }
        return videos2020;
      case "vg1":
        let videosvg1 = [];
        for (let i = 1; i <= maxVideoAmount; i++) {
          if (videoData["vg1"][i - 1].description !== "") {
            if (videoData["vg1"][i - 1].thumbnail) {
              videosvg1.push(
                <HoverVideoPlayer
                  key={i}
                  pausedOverlay={
                    <img
                      src={`Videos/vg1/thumbnails/${i}.png`}
                      alt=""
                      style={{
                        // Make the image expand to cover the video's dimensions
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  videoSrc={`/Videos/vg1/${i}.mp4`}
                  restartOnPaused
                  preload="metadata"
                  onClick={() => {
                    playVideo(`/Videos/vg1/${i}.mp4`);
                    setDesc(videoData["vg1"][i - 1].description);
                  }}
                />
              );
              continue;
            }
            videosvg1.push(
              <HoverVideoPlayer
                key={i}
                videoSrc={`/Videos/vg1/${i}.mp4`}
                restartOnPaused
                preload="metadata"
                onClick={() => {
                  playVideo(`/Videos/vg1/${i}.mp4`);
                  setDesc(videoData["vg1"][i - 1].description);
                }}
              />
            );
          }
        }
        return videosvg1;
      case "vg2":
        let videosvg2 = [];
        for (let i = 1; i <= maxVideoAmount; i++) {
          if (videoData["vg2"][i - 1].description !== "") {
            if (videoData["vg2"][i - 1].thumbnail) {
              videosvg2.push(
                <HoverVideoPlayer
                  key={i}
                  pausedOverlay={
                    <img
                      src={`Videos/vg2/thumbnails/${i}.png`}
                      alt=""
                      style={{
                        // Make the image expand to cover the video's dimensions
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  videoSrc={`/Videos/vg2/${i}.mp4`}
                  restartOnPaused
                  preload="metadata"
                  onClick={() => {
                    playVideo(`/Videos/vg2/${i}.mp4`);
                    setDesc(videoData["vg2"][i - 1].description);
                  }}
                />
              );
              continue;
            }
            videosvg2.push(
              <HoverVideoPlayer
                key={i}
                videoSrc={`/Videos/vg2/${i}.mp4`}
                restartOnPaused
                preload="metadata"
                onClick={() => {
                  playVideo(`/Videos/vg2/${i}.mp4`);
                  setDesc(videoData["vg2"][i - 1].description);
                }}
              />
            );
          }
        }
        return videosvg2;
      default:
        return <p>No videos for this year</p>;
    }
  };

  const changeYear = (newYear) => {
    document.getElementById(year).classList.remove(styles.active);
    setYear(newYear);
    document.getElementById(newYear).classList.add(styles.active);
  };

  return (
    <div className={styles.container}>
      <div>
        <video className={styles.hero} autoPlay muted loop>
          <source src="/Videos/hero.mp4" type="video/mp4" />
        </video>
      </div>

      <div className={styles.navbar}>
        <div className={styles.logo}>
          <img draggable={false} src={logo} width="200" alt=""></img>
        </div>
        <div className={styles.navlinks}>
          <p onClick={() => goto("ommeg")}>Om Meg</p>
          <p onClick={() => goto("prosjekt")}>Prosjekter</p>
          <p onClick={() => goto("kontakt")}>Kontakt</p>
        </div>
        <div className={styles.navlinksMobileIcon}>
          <FontAwesomeIcon
            icon={displayNavMenu ? faClose : faBars}
            size={"3x"}
            onClick={() =>
              displayNavMenu
                ? setDisplayNavMenu(false)
                : setDisplayNavMenu(true)
            }
          />
        </div>
        <div className={displayNavMenu ? `${styles.navlinksMobile}` : `hidden`}>
          <p
            onClick={() => {
              setDisplayNavMenu(false);
              goto("ommeg");
            }}
          >
            Om Meg
          </p>
          <p
            onClick={() => {
              setDisplayNavMenu(false);
              goto("prosjekt");
            }}
          >
            Prosjekter
          </p>
          <p
            onClick={() => {
              setDisplayNavMenu(false);
              goto("kontakt");
            }}
          >
            Kontakt
          </p>
        </div>
      </div>

      <div id="ommeg" className={styles.welcome}>
        <div id="ommeg-bilde" className={styles.welcomeBildet}>
          <img src="/Videos/Portrett.PNG" className={styles.bildet} alt="meg" />
        </div>

        <div id="ommeg-tekst" className={styles.welcomeTekst}>
          <h1>Gustav H. Egebakken</h1>
          <p>
            Jeg går nå siste året på yrkesfaglig studieprogram Medieproduksjon
            på Elvebakken videregående. Jeg valgte å gå IM fordi IT og media er
            to ting jeg hele livet har hatt interesse for.
          </p>
          <p>
            På skolen har jeg dette skoleåret valgt å fordype meg innenfor
            innholdsproduksjon der jeg har jobbet med oppdragsfilm i min
            ungdomsbedrift. Her har jeg jobbet med kundebehandling, opptak og
            redigering for kunder. Gjennom dette arbeidet har jeg lært mye om
            både teknisk utstyr, men også prosessen og viktigheten av å levere i
            tide.
          </p>
        </div>
      </div>

      <div className={styles.chooseYear}>
        <button
          id="2020"
          className={`${styles.Year} ${styles.active}`}
          onClick={() => changeYear("2020")}
        >
          2020
        </button>
        <button
          id="vg1"
          className={styles.Year}
          onClick={() => changeYear("vg1")}
        >
          vg1
        </button>
        <button
          id="vg2"
          className={styles.Year}
          onClick={() => changeYear("vg2")}
        >
          vg2
        </button>
      </div>

      <div id="prosjekt" className={styles.projects}>
        {rederSwitch(year)}
      </div>

      <div
        className={activeVideo !== null ? `${styles.videoPlayer}` : `hidden`}
      >
        <div className={styles.closeBtn} onClick={() => stopVideo()}>
          <FontAwesomeIcon icon={faClose} size={"3x"} />
        </div>

        <video
          ref={video}
          controls
          autoPlay
          loop
          src={activeVideo}
          type="video/mp4"
        ></video>
        <p>{desc}</p>
      </div>

      <div id="kontakt" className={styles.contact}>
        <div className={`${styles.contactItem} ${styles.hiddenMobile} `}>
          <p onClick={() => goto("ommeg")} className={styles.contactLink}>
            Om Meg
          </p>
          <p onClick={() => goto("prosjekt")} className={styles.contactLink}>
            Prosjekter
          </p>
          <p onClick={() => goto("kontakt")} className={styles.contactLink}>
            Kontakt
          </p>
        </div>
        <div className={styles.contactItem}>
          <p>Gustav H. Egebakken</p>
          <p>gustavegebakken@gmail.com</p>
          <p>+47 990 80 123</p>
        </div>
        <div className={`${styles.contactItem} ${styles.hiddenMobile} `}>
          <a
            href="https://www.facebook.com/gustav.egebakken"
            className={styles.contactLink}
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/gustav.hadland"
            className={styles.contactLink}
          >
            Instagram
          </a>
          <a
            href="https://www.linkedin.com/in/gustav-hadland-egebakken-3a5461250"
            className={styles.contactLink}
          >
            LinkedIn
          </a>
        </div>
      </div>
      <img draggable={false} src={logo} width="100" alt=""></img>
      <div className={styles.c}>
        <p>© 2023 gustavegebakken.no - v1.5</p>
      </div>
      {window.innerWidth < 800 ? (
        <dialog id="tlfModal" className={styles.dialog} open>
          <h2>Denne siden er laget for PC, ikke telefon...</h2>
        </dialog>
      ) : null}
    </div>
  );
}

export default App;
